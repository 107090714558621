import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import EnhancedTableToolbar from "./tableToolBar"
import { Auth, API } from "aws-amplify"
import awsmobile from "../aws-exports"
import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import loadable from "@loadable/component"
const JsonViewer = loadable(() => import("react-json-view"))

const rows = []

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Function Name" },
  { id: "date", numeric: true, disablePadding: false, label: "Date" },
  { id: "time", numeric: true, disablePadding: false, label: "Time" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  { id: "complete", numeric: true, disablePadding: false, label: " " },
]
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{
              align: "left",
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}))

function EpochToDate(epoch) {
  if (epoch < 10000000000) epoch *= 1000
  epoch = new Date(epoch).toString()
  return epoch
}

function formatEventKey(event) {
  let split = " "
  let name = " "
  let epoch = " "
  let epochTime = " "
  let dateTime = " "
  let splitDateTime = " "
  let date = " "
  let time = " "
  let status = " "
  let complete = " "
  if (event.Key.includes("skip")) {
    split = event.Key.split("/")
    name = split[3]
    epoch = split[4].split("-")
    epochTime = epoch.slice(0, -2)
    dateTime = EpochToDate(epochTime[0])
    splitDateTime = dateTime.split(" ")
    date =
      splitDateTime[0] +
      " " +
      splitDateTime[1] +
      " " +
      splitDateTime[2] +
      " " +
      splitDateTime[3]
    time = splitDateTime[4]
    status = "Skipped"
    complete = " "

    return {
      key: event.Key,
      name: name,
      date: date,
      time: time,
      status: status,
      complete: complete,
    }
  } else if (event.Key.includes("replayedEvents")) {
    split = event.Key.split("/")
    name = split[2]
    epoch = split[5].split("-")
    epochTime = epoch.slice(0, -2)
    dateTime = EpochToDate(epochTime[0])
    splitDateTime = dateTime.split(" ")
    date =
      splitDateTime[0] +
      " " +
      splitDateTime[1] +
      " " +
      splitDateTime[2] +
      " " +
      splitDateTime[3]
    time = splitDateTime[4]
    status = "Replayed"
    complete = " "

    return {
      key: event.Key,
      name: name,
      date: date,
      time: time,
      status: status,
      complete: complete,
    }
  } else {
    split = event.Key.split("/")
    name = split[2]
    epoch = split[3].split("-")
    epochTime = epoch.slice(0, -2)
    dateTime = EpochToDate(epochTime[0])
    splitDateTime = dateTime.split(" ")
    date =
      splitDateTime[0] +
      " " +
      splitDateTime[1] +
      " " +
      splitDateTime[2] +
      " " +
      splitDateTime[3]
    time = splitDateTime[4]
    status = "Errored"
    complete = " "

    return {
      key: event.Key,
      name: name,
      date: date,
      time: time,
      status: status,
      complete: complete,
    }
  }
}

function formatDate(selectedDate) {
  const dateTime = selectedDate.toString()
  const splitDateTime = dateTime.split(" ")
  const date =
    splitDateTime[0] +
    " " +
    splitDateTime[1] +
    " " +
    splitDateTime[2] +
    " " +
    splitDateTime[3]

  return date
}

function formatTime(selectedTime) {
  const dateTime = selectedTime.toString()
  const splitDateTime = dateTime.split(" ")
  const time = splitDateTime[4]
  return time
}

function filterTable(
  lambdaEvents,
  startDate,
  startTime,
  endDate,
  endTime,
  functionName,
  functionStatus
) {
  let filteredLambdaEventItems = []
  let startDateObj = Date.parse(startDate)
  let endDateObj = Date.parse(endDate)
  let startTimeObj = startTime
  let endTimeObj = endTime

  for (var i = 0; i < lambdaEvents.length; i++) {
    let canAdd = true

    if (functionName !== "" && lambdaEvents[i].name !== functionName) {
      canAdd = false
    } else if (
      functionStatus !== "" &&
      lambdaEvents[i].status !== functionStatus
    ) {
      canAdd = false
    } else if (
      Date.parse(lambdaEvents[i].date) < startDateObj ||
      Date.parse(lambdaEvents[i].date) > endDateObj
    ) {
      canAdd = false
    } else if (
      lambdaEvents[i].time < startTimeObj.toString() ||
      lambdaEvents[i].time > endTimeObj.toString()
    ) {
      canAdd = false
    }

    if (canAdd) {
      filteredLambdaEventItems.push(lambdaEvents[i])
    }
  }

  return filteredLambdaEventItems
}

export default function EnhancedTable(props) {
  const {
    setEventReplayResponse,
    setEventReplayPopup,
    functionName,
    functionStatus,
    selectedStartDate,
    selectedEndDate,
    selectedStartTime,
    selectedEndTime,
    isSubmitState,
    setIsSubmitState,
    isFilterApplied,
    setIsFilterApplied,
  } = props

  const classes = useStyles()
  const [order, setOrder] = React.useState("asc")
  const [orderBy, setOrderBy] = React.useState("date")
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [bucketKey, setBucketKey] = React.useState("")
  const [objectBody, setObjectBody] = React.useState([])
  const [lambdaEventItems, setLambdaEventItems] = React.useState([])
  const [rawLambdaEventItems, setRawLambdaEventItems] = React.useState([])
  const [refreshEventData, setRefreshEventData] = React.useState(false)

  const startDate = formatDate(selectedStartDate)
  const endDate = formatDate(selectedEndDate)
  const startTime = formatTime(selectedStartTime)
  const endTime = formatTime(selectedEndTime)

  useEffect(() => {
    const getData = async () => {
      const apiName = awsmobile.API.endpoints[0].name
      const path = "/listMessages"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: {
          functionName: "dev-failure-lambda",
        },
      }
      const data = await API.get(apiName, path, myInit)
      const formattedData = data.map(formatEventKey)
      setLambdaEventItems(formattedData)
      setRawLambdaEventItems(formattedData)
      // reset selecteds
      setSelected([])
    }
    getData()
  }, [refreshEventData])

  useEffect(() => {
    const getBody = async () => {
      const apiName = awsmobile.API.endpoints[0].name
      const path = "/getMessageBody"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: {
          bucketKey: bucketKey,
        },
      }
      const data = await API.get(apiName, path, myInit)
      const object = { key: bucketKey, value: data }
      setObjectBody(previousState => [...previousState, object])
    }
    if (bucketKey !== "") {
      getBody()
    }
  }, [bucketKey])

  useEffect(() => {
    const filterData = async () => {
      if (isSubmitState) {
        const filteredData = filterTable(
          rawLambdaEventItems,
          startDate,
          startTime,
          endDate,
          endTime,
          functionName,
          functionStatus
        )
        setLambdaEventItems(filteredData)
        setIsSubmitState(false)
        setIsFilterApplied(true)
      }
      if (!isFilterApplied) {
        setLambdaEventItems(rawLambdaEventItems)
        setIsFilterApplied(true)
      }
    }
    filterData()
  }, [
    rawLambdaEventItems,
    startDate,
    startTime,
    endDate,
    endTime,
    functionName,
    functionStatus,
    isFilterApplied,
    isSubmitState,
    setIsFilterApplied,
    setIsSubmitState,
  ])

  const triggerReloadEventData = () => {
    setRefreshEventData(!refreshEventData)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.key)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, key) => {
    const selectedIndex = selected.indexOf(key)
    let newSelected = []
    setBucketKey(key)

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  function getObjectData(rowKey) {
    for (var i = 0; i < objectBody.length; i++) {
      if (objectBody[i].key === rowKey) {
        return objectBody[i].value[0]
      }
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          selected={selected}
          setEventReplayResponse={setEventReplayResponse}
          setEventReplayPopup={setEventReplayPopup}
          triggerReloadEventData={triggerReloadEventData}
          functionStatus={functionStatus}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={lambdaEventItems.length}
            />
            <TableBody>
              {stableSort(lambdaEventItems, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.key)
                  const labelId = `enhanced-table-checkbox-${index}`
                  return (
                    <React.Fragment key={row.key}>
                      <TableRow
                        hover
                        onClick={event => handleClick(event, row.key)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.date}</TableCell>
                        <TableCell align="left">{row.time}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                        <TableCell align="left">{row.complete}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={isItemSelected}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              <Typography>Key: {row.key}</Typography>
                              <JsonViewer
                                src={
                                  typeof getObjectData(row.key) === "undefined"
                                    ? JSON.parse(
                                        '{"state" : "please reclick to load JSON"}'
                                      )
                                    : JSON.parse(
                                        JSON.stringify(getObjectData(row.key))
                                      )
                                }
                                displayDataTypes={false}
                                enableClipboard={true}
                                displayObjectSize={false}
                              />
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, 500, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
