import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import loadable from '@loadable/component'
import Divider from '@material-ui/core/Divider';
const JsonViewer = loadable(() => import('react-json-view'));

const UseStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  },
}));

const handleCloseResponse = (event, setEventReplayPopup) => { 
  setEventReplayPopup(false);
}

const handleExpandClick = (event, expanded, setExpanded) => {
  setExpanded(!expanded);
};

const EventReplayResponseAlert = (props) => { 
  const classes = UseStyles();
  const { eventReplayResponse, eventReplayPopup, setEventReplayPopup } = props
  const [expanded, setExpanded] = React.useState(false);
  const isError = eventReplayResponse.some(response => response.hasOwnProperty("FunctionError"))
  return (
    <div className={classes.root}>
      <Collapse in={eventReplayPopup}>
        <Card>
          <CardContent>
            { isError ? (
                <Typography variant="h5" component="h2">
                  Error replaying events
                </Typography>
              ) : (
                <Typography variant="h5" component="h2">
                  Replay event successful
                </Typography>
              )
            }
            <Button
              size="small"
              onClick={(e) => handleExpandClick(e, expanded, setExpanded)}
              endIcon={<ArrowForwardIosIcon />}
            >
              output
            </Button>
            <Collapse in={expanded}>
              <br />
              <Paper variant="outlined">
                <GridList cellHeight={'auto'} cols={1}>
                  {eventReplayResponse.map((response) => 
                    <GridListTile>
                      <Typography variant="body2" gutterBottom>Lambda: {response.LambdaArn}</Typography>
                      <Typography variant="body2" gutterBottom>Invoke output:</Typography>
                      <Paper  variant="outlined">
                        <JsonViewer
                          src={JSON.parse(response.Payload)}
                          displayDataTypes={false}
                          enableClipboard={true}
                          displayObjectSize={false}
                        />
                      </Paper>
                      <br/>
                      <Divider light />
                    </GridListTile>
                )}
                </GridList>
              </Paper>
            </Collapse>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={(e) => handleCloseResponse(e, setEventReplayPopup)}>close</Button>
          </CardActions>
        </Card>
      </Collapse>
    </div>
  );
}

EventReplayResponseAlert.propTypes = {
  eventReplayResponse: PropTypes.array.isRequired,
  eventReplayPopup: PropTypes.bool.isRequired,
  setEventReplayPopup: PropTypes.func.isRequired,
}

export default EventReplayResponseAlert;