import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
 
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.30),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.55),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

export default function Form(props){
    const {selectedStartDate, setSelectedStartDate, selectedEndDate, setSelectedEndDate,
          selectedStartTime, setSelectedStartTime, selectedEndTime, setSelectedEndTime,
          functionName, setFunctionName, functionStatus, setFunctionStatus,
          isSubmitState, setIsSubmitState, isFilterApplied, setIsFilterApplied} = props;

    const handleStartDateChange = (selectedStartDate) => {
      setSelectedStartDate(selectedStartDate);
    };
  
    const handleEndDateChange = (selectedEndDate) => {
      setSelectedEndDate(selectedEndDate);
    };
  
    const handleStartTimeChange = (selectedStartTime) => {
      setSelectedStartTime(selectedStartTime);
    };
  
    const handleEndTimeChange = (selectedEndTime) => {
      setSelectedEndTime(selectedEndTime);
    };

    const handleFunctionNameChange = (event) => {
        setFunctionName(event.target.value);
    }

    const handleFunctionStatusChange = (event) => {
        setFunctionStatus(event.target.value);
    }

    const handleButtonSubmitChange = () => {
        setIsSubmitState(true);
    }

    const handleFilterClearChange = () => {
        setSelectedStartDate(new Date('2020-01-01T21:11:54'));
        setSelectedEndDate(new Date());
        setSelectedStartTime(new Date('2020-01-01T00:00:00'));
        setSelectedEndTime(new Date('2020-01-01T23:59:59'));
        setFunctionName("");
        setFunctionStatus("");
        setIsSubmitState(false);
        setIsFilterApplied(false)
    }
    
    const classes = useStyles();
    
    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
            <div className="flexbox-container">
                <div>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="start-date-picker-inline"
                    label="Start Date"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </div>
                <div>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="end-date-picker-inline"
                    label="End Date"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </div>
            </div>
            <div className="flexbox-container">
                <div>
                    <KeyboardTimePicker
                        margin="normal"
                        id="start-time-picker"
                        label="Start Time"
                        value={selectedStartTime}
                        onChange={handleStartTimeChange}
                        KeyboardButtonProps={{
                        'aria-label': 'change time',
                        }}
                    />
                </div>
                <div>
                    <KeyboardTimePicker
                        margin="normal"
                        id="end-time-picker"
                        label="End Time"
                        value={selectedEndTime}
                        onChange={handleEndTimeChange}
                        KeyboardButtonProps={{
                        'aria-label': 'change time',
                        }}
                    />
                </div>
            </div>
            </Grid>
            </MuiPickersUtilsProvider>
            <div className="flexbox-container"
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                <div className={classes.root}>
                    <Toolbar>
                        <div className={classes.searchIcon}>
                        <SearchIcon />
                        </div>
                        <InputBase
                        id="search-bar"
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        value={functionName}
                        onChange={handleFunctionNameChange}
                        inputProps={{ 'aria-label': 'search' }}
                        />
                    </Toolbar>
                </div> 
                <div>
                    <FormControl className={classes.formControl}>
                    <Select
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    onChange={handleFunctionStatusChange}
                    value={functionStatus}
                    >
                    <MenuItem value={' '} disabled>
                        Status
                    </MenuItem>
                    <MenuItem value={"Errored"}>Errored</MenuItem>
                    <MenuItem value={"Replayed"}>Replayed</MenuItem>
                    <MenuItem value={"Skipped"}>Skipped</MenuItem>
                    </Select>
                    </FormControl>
                </div>
                <div
                 style={{
                    marginTop: "10px",
                }}>
                    <Button 
                    variant="contained"
                    value={isSubmitState}
                    onClick={handleButtonSubmitChange}
                    >Submit</Button>
                </div>
                <div
                 style={{
                    marginTop: "10px",
                }}>
                    <Button 
                    variant="contained"
                    value={isFilterApplied}
                    onClick={handleFilterClearChange}
                    >Clear</Button>
                </div>
            </div>   
        </div>   
    );
}