/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import EventReplayResponseAlert from "./eventReplayResponseAlert"
import Header from "./header"
import BaseTable from "./table"
import Form from "./form"

import "./layout.css"

const Layout = ({ children }) => {
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    new Date("2020-01-01T21:11:54")
  )
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date())
  const [selectedStartTime, setSelectedStartTime] = React.useState(
    new Date("2020-01-01T00:00:00")
  )
  const [selectedEndTime, setSelectedEndTime] = React.useState(
    new Date("2020-01-01T23:59:59")
  )
  const [functionName, setFunctionName] = React.useState("")
  const [functionStatus, setFunctionStatus] = React.useState("")
  const [eventReplayResponse, setEventReplayResponse] = React.useState([])
  const [eventReplayPopup, setEventReplayPopup] = React.useState(false)
  const [isSubmitState, setIsSubmitState] = React.useState(false)
  const [isFilterApplied, setIsFilterApplied] = React.useState(true)

  return (
    <>
      <Header siteTitle="Event Replay" />
      <EventReplayResponseAlert
        eventReplayResponse={eventReplayResponse}
        eventReplayPopup={eventReplayPopup}
        setEventReplayPopup={setEventReplayPopup}
      />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <Form
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedStartTime={selectedStartTime}
          setSelectedStartTime={setSelectedStartTime}
          selectedEndTime={selectedEndTime}
          setSelectedEndTime={setSelectedEndTime}
          functionName={functionName}
          setFunctionName={setFunctionName}
          functionStatus={functionStatus}
          setFunctionStatus={setFunctionStatus}
          isSubmitState={isSubmitState}
          setIsSubmitState={setIsSubmitState}
          isFilterApplied={isFilterApplied}
          setIsFilterApplied={setIsFilterApplied}
        />
        <BaseTable
          setEventReplayResponse={setEventReplayResponse}
          setEventReplayPopup={setEventReplayPopup}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedStartTime={selectedStartTime}
          setSelectedStartTime={setSelectedStartTime}
          selectedEndTime={selectedEndTime}
          setSelectedEndTime={setSelectedEndTime}
          functionName={functionName}
          setFunctionName={setFunctionName}
          functionStatus={functionStatus}
          setFunctionStatus={setFunctionStatus}
          isSubmitState={isSubmitState}
          setIsSubmitState={setIsSubmitState}
          isFilterApplied={isFilterApplied}
          setIsFilterApplied={setIsFilterApplied}
        />
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
