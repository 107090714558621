import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import FilterListIcon from "@material-ui/icons/FilterList"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import { lighten, makeStyles } from "@material-ui/core/styles"
import { Auth, API } from "aws-amplify"
import awsmobile from "../aws-exports"

EnhancedTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  setEventReplayResponse: PropTypes.func.isRequired,
  setEventReplayPopup: PropTypes.func.isRequired,
  triggerReloadEventData: PropTypes.func.isRequired,
  functionStatus: PropTypes.string.isRequired,
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}))

export default function EnhancedTableToolbar(props) {
  const classes = useToolbarStyles()
  const {
    selected,
    setEventReplayResponse,
    setEventReplayPopup,
    triggerReloadEventData,
    functionStatus,
  } = props
  const numSelected = selected.length

  const replayEvents = async (
    event,
    selected,
    setEventReplayResponse,
    setEventReplayPopup
  ) => {
    try {
      const apiName = awsmobile.API.endpoints[0].name
      const path = "/replayLambda"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          s3ObjectKeys: selected,
        },
      }
      const response = await API.put(apiName, path, myInit)
      setEventReplayResponse(response)
      setEventReplayPopup(true)
      triggerReloadEventData()
    } catch (err) {
      console.error(err)
      alert(`Something went wrong: "${err.message}"`)
    }
  }

  const skipEvent = async (event, selected) => {
    try {
      const apiName = awsmobile.API.endpoints[0].name
      const path = "/moveMessages"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          action: "error2skip",
          keys: selected,
        },
      }
      await API.put(apiName, path, myInit)
      triggerReloadEventData()
    } catch (err) {
      console.error(err)
      alert(`Something went wrong: "${err.message}"`)
    }
  }

  const unSkipEvent = async (event, selected) => {
    try {
      const selectedFiltered = selected.map(selectedItem =>
        selectedItem.replace("skip/", "")
      )
      const apiName = awsmobile.API.endpoints[0].name
      const path = "/moveMessages"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          action: "skip2error",
          keys: selectedFiltered,
        },
      }
      await API.put(apiName, path, myInit)
      triggerReloadEventData()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <h3>Lambda Functions</h3>
        </Typography>
      )}
      {numSelected > 0 ? (
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button
            onClick={event =>
              replayEvents(
                event,
                selected,
                setEventReplayResponse,
                setEventReplayPopup
              )
            }
          >
            Replay
          </Button>
          {functionStatus === "Skipped" ? (
            <Button onClick={event => unSkipEvent(event, selected)}>
              Unskip
            </Button>
          ) : (
            <Button onClick={event => skipEvent(event, selected)}>Skip</Button>
          )}
        </ButtonGroup>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}
